<!-- SearchBox -->
<script setup lang="ts">
import { ref, computed, VNodeChild, watch } from "vue";
import { LANGUAGE } from "../../api/backend";

// import SendIcon from "@fluentui/svg-icons/icons/send_24_filled.svg";
import SendIcon from "@fluentui/svg-icons/icons/send_24_regular.svg";
import MicIcon from "@fluentui/svg-icons/icons/mic_24_regular.svg";
import MicPulseIcon from "@fluentui/svg-icons/icons/mic_pulse_24_regular.svg";
import MicRecordIcon from "@fluentui/svg-icons/icons/mic_record_24_regular.svg";
import StopIcon from "@fluentui/svg-icons/icons/stop_24_filled.svg";
import { useQuasar } from "quasar";
import { SwitchRoot, SwitchThumb } from "radix-vue";
import { useStore } from "../../stores/store";

// 음성인식 상태를 정의한 enum
enum VoiceRecognitionState {
  IDLE = 0, // 음성인식 전
  LANGUAGE_SELECT = 1, // 언어 선택
  RECOGNITION_STARTED = 2, // 음성인식 시작
  ANSWER_STARTED = 3, // 음성답변 시작
  COMPLETED = 4 // 끝
}

interface Props {
  inputLabel: string;
  placeholder: string;
  sendLabel: string;
  sendEvent: () => void;
  channelKey?: number;
  handleSttClick?: () => void;
  // selectedLanguage?: string;
  ttsOption?: number;
  micStatus?: string;
  MicStatus?: object;
  stopTtsFunc?: () => void;
}
const props = defineProps<Props>();
// const emit = defineEmits(["update:selectedLanguage"]);
const emit = defineEmits(["sttStart", "languageChange"]);
const searchContent = defineModel<string>({ required: true }); // input 내용을 관리하는 변수
const showLanguageSelect = ref(false);
const selectedLanguage = ref(LANGUAGE.KOREAN.code); // 기본값 한국어
// const selectedLanguage = ref("");
const voiceState = ref(VoiceRecognitionState.IDLE); // 음성인식 상태

const $q = useQuasar();

// 버튼 활성화 여부를 계산하는 속성
const isButtonDisabled = computed(() => searchContent.value === "");
const isChannel250 = computed(() => props.channelKey !== 1);
const showMicIcon = computed(() => isChannel250.value && !searchContent.value);
// const showMicIcon = computed(() => !searchContent.value);

// 음성인식 상태에 따른 버튼 텍스트
// const getButtonLabel = computed(() => {
//   switch (voiceState.value) {
//     case VoiceRecognitionState.RECOGNITION_STARTED:
//       return "음성인식 중지";
//     case VoiceRecognitionState.ANSWER_STARTED:
//       return "음성답변 중지";
//     default:
//       return props.sendLabel;
//   }
// });

// 음성인식 상태에 따른 아이콘 컴포넌트 선택
const getStateIcon = computed(() => {
  if (voiceState.value === VoiceRecognitionState.LANGUAGE_SELECT) {
    return MicPulseIcon;
  } else if (
    voiceState.value === VoiceRecognitionState.RECOGNITION_STARTED ||
    voiceState.value === VoiceRecognitionState.ANSWER_STARTED
  ) {
    return StopIcon; // 정지 아이콘 표시
  } else if (props.ttsOption === 1) {
    return MicRecordIcon;
  }
  return MicIcon;
});

// 음성인식 상태 텍스트
const getStateText = computed(() => {
  switch (voiceState.value) {
    case VoiceRecognitionState.LANGUAGE_SELECT:
      return "";
    case VoiceRecognitionState.RECOGNITION_STARTED:
      return "음성 인식 중...";
    case VoiceRecognitionState.ANSWER_STARTED:
      return "음성 답변 중...";
    default:
      return "";
  }
});

watch(
  () => props.ttsOption,
  (newVal, oldVal) => {
    console.log("ttsOption changed from", oldVal, "to", newVal);

    // ttsOption 값에 따른 voiceState 업데이트
    if (
      newVal === 1 &&
      voiceState.value < VoiceRecognitionState.RECOGNITION_STARTED
    ) {
      // 음성 인식 시작
      voiceState.value = VoiceRecognitionState.RECOGNITION_STARTED;
      console.log("상태 변경: 음성 인식 시작 (2)");
    } else if (newVal === 2) {
      // 음성 인식이 끝나고 답변 시작 - 무조건 답변 상태로 변경
      voiceState.value = VoiceRecognitionState.ANSWER_STARTED;
      console.log("상태 변경: 음성 답변 시작 (3)");
    } else if (
      newVal === 0 &&
      voiceState.value !== VoiceRecognitionState.IDLE
    ) {
      // 모든 과정 완료
      voiceState.value = VoiceRecognitionState.COMPLETED;
      console.log("상태 변경: 완료 (4)");
      setTimeout(() => {
        voiceState.value = VoiceRecognitionState.IDLE;
        console.log("상태 변경: 초기화 (0)");
      }, 1000);
    }
  }
);

const handleButtonClick = () => {
  if (isChannel250.value && !searchContent.value) {
    // if (!searchContent.value) {
    // props.handleSttClick?.();
    handleVoiceButtonClick();
  } else {
    props.sendEvent();
  }
};

const handleVoiceButtonClick = () => {
  switch (voiceState.value) {
    case VoiceRecognitionState.IDLE:
      $q.notify({
        message: "언어 선택 후 마이크 버튼을 눌러주세요.",
        color: "positive",
        timeout: 6000
      });

      // 첫 클릭: 언어 선택 UI 표시
      showLanguageSelect.value = true;
      voiceState.value = VoiceRecognitionState.LANGUAGE_SELECT;
      break;

    case VoiceRecognitionState.LANGUAGE_SELECT:
      // 언어 선택 후 클릭: 음성인식 시작
      if (!selectedLanguage.value) {
        return;
      }
      showLanguageSelect.value = false;
      voiceState.value = VoiceRecognitionState.RECOGNITION_STARTED;

      emit("sttStart", {
        language: selectedLanguage.value,
        isStarting: true,
        newTtsOption: 1
      });

      $q.notify({
        message: "음성인식이 시작되었습니다.",
        color: "positive",
        timeout: 2000
      });
      break;

    case VoiceRecognitionState.RECOGNITION_STARTED:
    case VoiceRecognitionState.ANSWER_STARTED:
      // 정지 버튼 클릭: 음성인식/답변 중지
      stopVoiceRecognition();
      break;

    default:
      break;
  }
};

const startAnswerMode = () => {
  // 음성답변 시작으로 상태 변경
  voiceState.value = VoiceRecognitionState.ANSWER_STARTED;
};

const stopVoiceRecognition = () => {
  // 음성인식/답변 중지 처리
  emit("sttStop");
  props.stopTtsFunc?.();

  $q.notify({
    message: "음성인식이 중지되었습니다.",
    color: "positive",
    timeout: 3000
  });

  // 상태 즉시 초기화하여 status-indicator가 바로 사라지도록 함
  voiceState.value = VoiceRecognitionState.IDLE;
  showLanguageSelect.value = false;
};

// 버튼 클래스 계산
const buttonClass = computed(() => ({
  mikeBtn: voiceState.value === VoiceRecognitionState.IDLE,
  mikeRecording: voiceState.value === VoiceRecognitionState.RECOGNITION_STARTED,
  mikeAnswering: voiceState.value === VoiceRecognitionState.ANSWER_STARTED,
  stopBtn:
    voiceState.value === VoiceRecognitionState.RECOGNITION_STARTED ||
    voiceState.value === VoiceRecognitionState.ANSWER_STARTED
}));

const handleSttClick = () => {
  if (!showLanguageSelect.value) {
    // 첫 클릭: 언어 선택 UI 표시
    showLanguageSelect.value = true;
  } else {
    // 언어 선택 후 두 번째 클릭: STT 시작
    if (!selectedLanguage.value) {
      return;
    }
    showLanguageSelect.value = false;
    emit("sttStart", {
      language: selectedLanguage.value,
      isStarting: true,
      newTtsOption: 1
    });
  }
};

const handleLanguageChange = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  selectedLanguage.value = target.value;
  emit("languageChange", target.value);
};

// const buttonClass = computed(() => ({
//   mikeBtn: props.ttsOption === 0 && !showLanguageSelect.value,
//   mikeRecording: props.ttsOption === 1,
//   mikeRecordingFix: props.ttsOption === 2
// }));

const getMicIcon = () => {
  // if (!showLanguageSelect.value) {
  //   return MicIcon;
  // }
  if (showLanguageSelect.value) {
    return MicPulseIcon;
  }
  if (props.ttsOption === 1) {
    return MicRecordIcon;
  }
  return MicIcon;
};

const switchState = ref(false);
const preventSwitch = (event: Event) => {
  // switchState.value = !switchState.value;
  event.preventDefault(); // 기본 이벤트 방지
  event.stopPropagation(); // 이벤트 전파 방지
  $q.notify({
    message: "서비스가 준비중입니다.",
    color: "negative",
    timeout: 2000
  });
};

// const handleKeydown = (event: KeyboardEvent) => {
//   if (event.ctrlKey && event.key === "Enter") {
//     props.sendEvent();
//   }
// };
const handleKeydown = (event: KeyboardEvent) => {
  // 한글 입력 중일 때는 무시
  if (event.isComposing) return;

  if (event.ctrlKey && event.key === "Enter") {
    props.sendEvent();
  }
};
const store = useStore();

const isSahthisYn = computed(
  () =>
    store.currentChannel?.channel_key === 397 && store.myInfo?.user_key === 75
);
</script>

<template>
  <div class="searchInputBox">
    <!-- <div class="subheader subheader__search">{{ props.inputLabel }}</div> -->
    <div class="textarea-wrapper">
      <!-- 언어 선택 select box -->

      <!-- <textarea
        rows="2"
        :aria-label="props.inputLabel"
        :placeholder="props.placeholder"
        @keydown.enter.ctrl="props.sendEvent"
        v-model="searchContent"
        :class="['searchInput', { 'searchInput--channel250': isChannel250 }]"
      ></textarea> -->
      <textarea
        rows="2"
        :aria-label="props.inputLabel"
        :placeholder="props.placeholder"
        @keydown="handleKeydown"
        v-model="searchContent"
        :class="['searchInput', { 'searchInput--channel250': isChannel250 }]"
      ></textarea>

      <div v-if="!isSahthisYn" class="powered-by-container">
        <span class="powered-by-text">Powered by</span>
        <a href="https://anytalk.com" target="_blank" class="anytalk-link">
          <!-- <img src="/src/assets/logo.png" alt="anytalk logo" class="anytalk-logo"> -->
          <span>anytalk.com</span>
          <img
            src="/src/assets/logo.png"
            alt="anytalk logo"
            class="anytalk-logo"
          />
        </a>
      </div>
      <!-- 입력에 따라 버튼 색상 변경 -->
      <!-- <button
        @click="props.sendEvent"
        class="sendButton"
        :disabled="isButtonDisabled"
      >
        {{ props.sendLabel }}
        <SendIcon />
      </button> -->
      <!-- 컨트롤 영역 (언어 선택 + 버튼) -->
      <div v-if="!isSahthisYn" class="gpt-area">
        <div>
          <label
            class="SwitchLabel"
            for="multi-query-mode"
            title="GPT를 기반으로 답변합니다."
          >
            <!-- <SwitchRoot
          class="SwitchRoot"
          id="muliti-query-mode"
          v-model:checked="switchState"
        > -->
            <SwitchRoot
              class="SwitchRoot"
              alt="GPT를 기반으로 답변합니다."
              id="muliti-query-mode"
              tabindex="1"
              :checked="switchState"
              @click="preventSwitch"
              ><p>GPT</p>
              <SwitchThumb class="SwitchThumb"> </SwitchThumb>
            </SwitchRoot>
            <!-- <p>GPT 모드</p> -->
          </label>
        </div>
      </div>
      <div class="control-area">
        <!-- 언어 선택 박스 -->
        <select
          v-if="showLanguageSelect"
          v-model="selectedLanguage"
          @change="handleLanguageChange"
          class="language-select"
        >
          <option value="" disabled>Language/언어</option>
          <option v-for="lang in LANGUAGE" :key="lang.code" :value="lang.code">
            {{ lang.name }}
          </option>
        </select>
        <!-- 전송/음성인식 버튼 -->
        <!-- <button
          @click="handleButtonClick"
          class="sendButton"
          :class="buttonClass"
          :disabled="!showMicIcon && isButtonDisabled"
        > -->

        <button
          @click="handleButtonClick"
          tabindex="0"
          class="sendButton"
          :class="{ 'pulse-animation': getStateIcon === MicPulseIcon }"
          :disabled="!showMicIcon && isButtonDisabled"
        >
          <template v-if="isChannel250">
            <template v-if="showMicIcon">
              <!-- 상태 표시 -->
              <div class="status-indicator" v-if="voiceState > 0">
                <span
                  class="status-dot"
                  :class="{
                    recording: voiceState === 2,
                    answering: voiceState === 3
                  }"
                ></span>
                <span class="status-text">{{ getStateText }}</span>
              </div>

              <!-- 아이콘 컴포넌트 -->
              <component :is="getStateIcon" class="icon" />
              <!-- <span>취소</span> -->
              <!-- 상태 텍스트 (정지 버튼일 때) -->
              <span
                v-if="voiceState === 2 || voiceState === 3"
                class="stop-text"
              ></span>
            </template>
            <template v-else>
              <SendIcon class="icon" />
            </template>
          </template>
          <template v-else>
            {{ props.sendLabel }}
            <SendIcon class="icon" />
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "../../styles/commons";
@use "../root/header";

.subheader {
  min-width: 40px;
}

.searchInputBox {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
  width: 100%;
  padding: 0px 10px;
  margin: 0 auto;
}

.textarea-wrapper {
  position: relative;
  width: 100%;
}

.searchInput {
  align-content: center;
  font-size: commons.$searchInputFontSize;
  margin-inline-end: 10px;
  width: 100%;
  /* padding: 10px 00px 10px 20px; */
  padding: 0px 0px 0px 20px;
  padding-right: 50px;
  padding-left: 83px;
  // border: 7px solid #4ec9a9;
  // border: 1px solid #e0e0e0;
  border: 3px solid #3eb697;
  border-radius: 20px;
  resize: none;
  box-sizing: border-box;

  &--channel250 {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    // text-align: center;

    // &::placeholder {
    //   text-align: center;
    //   position: absolute;
    //   left: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 100%;
    // }
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 1.5s infinite ease-in-out;
}

.gpt-area {
  position: absolute;
  left: 10px;
  top: 38%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}
.control-area {
  position: absolute;
  right: 10px;
  // top: 50%;
  top: 34%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

.language-select {
  // position: absolute;
  // top: -60px;
  // left: 90%;
  // transform: translateX(-50%);
  // width: 140px;
  // height: 40px;
  // background-color: white;
  // border-radius: 20px;
  // border: 1px solid #e0e0e0;
  // padding: 0 10px;
  // font-size: 14px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // z-index: 1000;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  padding: 0 5px;
  font-size: 12px;
  width: auto; /* 내용에 맞게 자동 조정 */
  max-width: 100px; /* 최대 너비 제한 */
}

.sendButton {
  // position: absolute;
  // right: 20px;
  /* height: 80% !important; */
  // top: 50%;
  // transform: translateY(-50%);
  /* background-color: #4ec9a9; */
  // background-color: white !important;
  // box-shadow: none !important;
  // border: none;
  // padding: 5px 10px;
  // border-radius: 5px;
  // min-width: 40px;
  // cursor: pointer;
  // transition:
  //   background-color 0.3s,
  //   transform 0.3s;
  /* background-color: white !important; */
  background: #f8f8f8;
  box-shadow: none !important;
  border: none;
  padding: 5px;
  border-radius: 5px;
  min-width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    /* background-color: white; */
    background: #f8f8f8;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    /* background-color: #03a584 !important; */
    /* transform: scale(1.005) !important; */
  }

  &.mikeRecording .icon {
    color: #3eb697;
    animation: pulse 1.5s infinite;
  }

  &.mikeAnswering .icon {
    color: #3eb697;
  }

  &.stopBtn {
    /* background-color: white !important; */
    background: #f8f8f8;

    .icon {
      color: #3eb697;
    }
  }

  .stop-text {
    font-size: 10px;
    margin-top: 2px;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    color: #ff4444;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.status-indicator {
  position: absolute;
  transform: translateX(-70%);
  display: flex;
  align-items: center;
  gap: 5px;
  // background: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 12px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &.recording {
    background-color: #3eb697;
    animation: blink 1s infinite;
  }

  &.answering {
    background-color: #3eb697;
    animation: blink 1s infinite;
  }
}

.status-text {
  font-size: 12px;
  color: #333;
  white-space: nowrap;
}
/* 언어 선택후 아이콘 애니메이션 적용 */
@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(1.2);
    filter: brightness(2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: brightness(1);
  }
}

.pulse-animation {
  animation: pulse 1.5s infinite ease-in-out;
}

.recording-status {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  background: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recording-dot {
  width: 8px;
  height: 8px;
  background-color: #ff4444;
  border-radius: 50%;
  animation: blink 1s infinite;
}

.recording-text {
  font-size: 12px;
  color: #333;
  white-space: nowrap;
}
.voice-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mic-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
}

.voice-mode {
  font-size: 10px;
  line-height: 1;
  margin-top: 2px;
  white-space: nowrap;
  color: #555;
}

/* 녹음 중일 때 특별한 스타일 */
.recording .mic-icon {
  color: #ff4444;
  animation: pulse 1.5s infinite;
}

.recording .voice-mode {
  color: #ff4444;
  font-weight: 500;
}

/* ttsOption이 2일 때 (연속 인식) */
.sendButton:has(.voice-mode:contains("연속 인식")) .mic-icon {
  color: #3eb697;
}

.sendButton:has(.voice-mode:contains("연속 인식")) .voice-mode {
  color: #3eb697;
}

/* 언어 선택 모드일 때 */
.sendButton:has(.voice-mode:contains("언어 선택")) .mic-icon {
  color: #0078d4;
}

.sendButton:has(.voice-mode:contains("언어 선택")) .voice-mode {
  color: #0078d4;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

@media (max-width: 900px) {
  .sendButton {
    right: 3px !important;
  }
}

@media (max-width: 1170px) {
  .sendButton {
    right: 3px !important;
  }
}

@media (max-width: 480px) {
  .searchInputBox {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
  }

  .searchInput {
    font-size: 0.8rem !important;
    padding: 5px 00px 5px 10px;
    padding-left: 80px;
  }
}

.SwitchLabel p {
  position: absolute;
  right: 7px;
  padding-left: 11px;
  font-size: 0.7rem;
  align-self: anchor-center;
  font-weight: 400;
  color: rgb(160, 160, 160);
}

.SwitchLabel {
  display: inline-flex;
  align-items: center;
  /* padding-left: 5px; */
  /* gap: 5px; */
  font-weight: bold;
  /* margin-bottom: 20px; */
  margin-bottom: 8px;
  width: 100%;
}
:deep(.SwitchRoot) {
  min-width: 58px !important;
  width: 22px;
  height: 15px;
  border-radius: 9999px;
  position: relative;
  box-shadow: none;
  -webkit-tap-highlight-color: rgb(84, 84, 84);
  padding: 0;
  margin: 0;
  &:focus {
    box-shadow: 0 0 0 2px rgb(84, 84, 84);
  }
  &[data-state="checked"] {
    background-color: rgb(144, 144, 144);
  }
}

:deep(.SwitchThumb) {
  display: block;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: none;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
  box-shadow: 0px 0px 1px 0px black;
  &[data-state="checked"] {
    transform: translateX(28px);
    background-color: #ffffff;
    border: 0.1px solid gray;
    box-shadow: 0px 0px 1px 0px black;
  }
}

:deep(.DialogRoot),
:deep(.DialogContent),
:deep([role="dialog"]),
:deep(.ReferCommonPopLayer),
:deep(.popLayer) {
  outline: none !important;
  box-shadow: none !important;
}

/* Powered by Anytalk.com 푸터 스타일 */
.powered-by-container {
  width: 100%;
  max-width: 830px; /* searchInputBox와 동일한 너비로 제한 */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 5px 0;
  margin: 0px auto 0; /* 상단 마진만 추가하고 중앙 정렬 */
  font-size: 12px;
  color: #888;
  position: relative !important; /* fixed 대신 relative로 변경 */
}

.powered-by-text {
  margin-right: 5px;
}

.anytalk-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #666;
}

.anytalk-logo {
  height: 18px;
  width: auto;
  margin: 0 5px 5px 5px;
}

@media (max-width: 480px) {
  .powered-by-container {
    font-size: 10px;
    padding: 3px 0;
  }

  .anytalk-logo {
    height: 14px;
  }
}
</style>
