<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle
} from "radix-vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
// import { sidebarKey } from "../components/common/sidebar/SidebarRoot.vue";

import { ref, inject } from "vue";

// const sidebarContext = inject(sidebarKey);

const props = defineProps({
  pPopTitle: {
    type: String,
    default: "애니톡"
  },
  pPopupInfos: {
    type: Object,
    require: false
  },
  pSetDefault: Function,
  pDisableOutsideClick: {
    type: Boolean,
    default: false
  },
  zIndex: {
    type: Number,
    default: 2000
  }, // 기본값 설정
  pMaxWidth: {
    type: String,
    default: "400px" // 기본 너비 설정
  }
});

const commonPopInfo = ref({
  title: props.pPopTitle,
  mainBtnColor: props.pPopupInfos?.mainColor
    ? props.pPopupInfos.mainColor
    : "blue",
  subBtnColor: props.pPopupInfos?.subColor ? props.pPopupInfos.subColor : "gray"
});

const setDefault = () => {
  if (props.pSetDefault) {
    props.pSetDefault();
  }
};

const getZIndex = () => {
  return props.zIndex || 2000; // 기본값
};
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" :style="{ zIndex: getZIndex() - 1 }" />
    <DialogContent
      class="DialogContent commonPopWrap"
      :style="{ zIndex: getZIndex(), maxWidth: pMaxWidth }"
      :disableOutsidePointerEvents="pDisableOutsideClick"
      :onPointerDownOutside="
        pDisableOutsideClick ? (e) => e.preventDefault() : undefined
      "
    >
      <DialogTitle class="DialogTitle commonPopHeader">
        <span>{{ commonPopInfo.title }}</span>
        <DialogClose as-child @click="setDefault">
          <CloseIcon class="cursorP" />
        </DialogClose>
      </DialogTitle>
      <div class="popBodyFooterWrap">
        <DialogDescription class="DialogDescription commonPopBody">
          <slot name="body"></slot>
        </DialogDescription>

        <div class="commonPopFooter">
          <slot name="actions">
            <button :style="commonPopInfo.mainBtnColor">확인</button>
            <button :style="commonPopInfo.subBtnColor">취소</button>
          </slot>
        </div>
      </div>
    </DialogContent>
  </DialogPortal>

  <!-- <div class="commonPopWrap">
      <div class="commonPopHeader">
        <span>{{ commonPopInfo.title }}</span>
        <span></span>
      </div>

      <div class="commonPopBody">
        <slot name="body"></slot>
      </div> -->
</template>

<style lang="scss" scoped></style>
