<script setup lang="ts">
import { DialogRoot } from "radix-vue";
import { computed, ref } from "vue";
import { createChannel } from "../api/backend";
import { usePopupStore } from "../stores/popups";
import { useStore } from "../stores/store";

import { useQuasar, QToggle } from "quasar";
import CommonPopLayer from "../popups/CommonPopLayer.vue";
import { channel } from "diagnostics_channel";
import router from "../routers";

const $q = useQuasar();
const store = useStore();
const popStore = usePopupStore();

const props = defineProps({
  pClosePop: Function
});

const popupTitle = "채널 생성";
// const popupInfos = ref({ mainColor: "skyblue", subColor: "gray" });

const channelOneLineDesc = ref("");
const channelName = ref("");

const channelSecureYn = ref(0);
const channelSecureStr4 = ref("");
const channelKmsYn = ref(1);
// const categories = ref([
//   { name: "기업", value: "company" },
//   { name: "식당", value: "company" },
//   { name: "카페", value: "company" },
//   { name: "공공장소", value: "company" },
//   { name: "종교", value: "company" }
// ]);

// const selectedCategory = ref("default");

// const changeCategory = (value) => {
//   selectedCategory.value = value;
// };

// const onChannelAdd = async () => {
//   if (channelName.value && channelOneLineDesc.value) {
//     await createChannel(
//       channelName.value,
//       channelOneLineDesc.value,
//       channelSecureStr4.value,
//       channelSecureYn.value,
//       channelKmsYn.value
//     );
//     await store.updateAllChannelList();
//     await store.updateMyChannelInfo();
//     channelName.value = "";
//     channelOneLineDesc.value = "";
//     channelSecureStr4.value = "";
//     channelSecureYn.value = 0;
//     channelKmsYn.value = 0;
//     $q.notify({
//       message: "채널이 생성되었습니다.",
//       color: "positive"
//     });
//   } else {
//     $q.notify({
//       message: "채널명을 입력하세요.",
//       color: "negative"
//     });
//   }
// };

const onChannelAdd = async () => {
  if (channelName.value && channelOneLineDesc.value) {
    await createChannel(
      channelName.value,
      channelOneLineDesc.value,
      channelSecureStr4.value,
      channelSecureYn.value,
      channelKmsYn.value
    );
    await store.updateAllChannelList();
    await store.updateMyChannelInfo();
    // console.log("가장 최근 채널", store.myChannelList[0]);
    channelName.value = "";
    channelOneLineDesc.value = "";
    channelSecureStr4.value = "";
    channelSecureYn.value = 0;
    channelKmsYn.value = 1;
    $q.notify({
      message: "채널이 생성되었습니다.",
      color: "positive"
    });
    popStore.confirmCloseParentPop = props.pClosePop;
    props.pClosePop();
    goToChatting(store.myChannelList[0]);
  } else {
    $q.notify({
      message: "채널명 입력하세요.",
      color: "negative"
    });
  }
};

const kmsToggle = computed({
  get: () => channelKmsYn.value === 1, // 1이면 true
  set: (value) => {
    channelKmsYn.value = value ? 1 : 0; // true이면 1, false이면 0으로 설정
  }
});

const secureToggle = computed({
  get: () => channelSecureYn.value === 1, // 1이면 true
  set: (value) => {
    channelSecureYn.value = value ? 1 : 0; // true이면 1, false이면 0으로 설정
  }
});

// const saveNewChannel = () => {
//   if (
//     channelName.value == "" ||
//     channelName.value == undefined ||
//     channelOneLineDesc.value == undefined ||
//     (channelSecureYn.value == 1 && channelSecureStr4.value.length < 4)
//   ) {
//     $q.notify({
//       message: "입력칸을 채워주세요.",
//       color: "negative"
//     });
//     return;
//   }
//   //  else if (
//   //   selectedCategory.value == "default" ||
//   //   selectedCategory.value == undefined
//   // ) {
//   //   $q.notify({
//   //     message: "카테고리를 선택해주세요.",
//   //     color: "negative"
//   //   });
//   //   return;
//   // }
//   popStore.openConfirmAskPopLayer();
//   popStore.confirmAskPopBody = "새 채널을 추가하시겠습니까?";
//   popStore.confirmCloseParentPop = props.pClosePop;
//   popStore.confirmAskOK = onChannelAdd;
//   popStore.confirmPopBody = "새로운 채널이 성공적으로 추가되었습니다.";
// };

const saveNewChannel = () => {
  if (
    channelName.value == "" ||
    channelName.value == undefined ||
    channelOneLineDesc.value == undefined ||
    (channelSecureYn.value == 1 && channelSecureStr4.value.length < 4)
  ) {
    $q.notify({
      message: "입력칸을 채워주세요.",
      color: "negative"
    });
    return;
  }

  // 이전 confirmAskOK 초기화 (중복 실행 방지)
  popStore.confirmAskOK = null;

  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "새 채널을 추가하시겠습니까?";
  popStore.confirmAskOK = async () => {
    if (!channelName.value || !channelOneLineDesc.value) {
      $q.notify({
        message: "입력란을 채워주세요.",
        color: "negative"
      });
      return;
    } else {
      await onChannelAdd();
      popStore.confirmAskPopYn = false; // 팝업 닫기
      popStore.confirmAskOK = null; // ✅
      // popStore.confirmCloseParentPop = props.pClosePop;
      // props.pClosePop();
      popStore.confirmPopBody = "새로운 채널이 성공적으로 추가되었습니다.";

      // debugger;
    }
  };
};
// const store = useStore()

const goToChatting = (channel: object) => {
  store.currentChannel = [];
  store.currentChannel = channel;
  sidebarContext?.toggleEnabled();
  // privateChannelDialog.value = false;
  router.push("/talk");
};

const toggleSecureColor = computed(() => {
  return channelSecureYn.value === 1 ? "#03ceae" : "#03ceae"; // 조건에 따라 색상 설정
});
</script>

<template>
  <CommonPopLayer
    :pPopTitle="popupTitle"
    pMaxWidth="380px"
    style="position: relative; z-index: 2000 !important"
    class="channelCreatePopLayout"
  >
    <template #body>
      <div class="channelCreateWrap">
        <!-- <div class="channelImg cursorP"></div> -->

        <div class="ChannelInfos flexAlign">
          <div class="channelName">
            <label class="fontSm" for="channelName">채널명</label>
            <input
              v-model="channelName"
              type="text"
              id="channelName"
              placeholder="채널명을 입력해주세요."
            />
          </div>

          <div class="secureWrap">
            <label class="featureLabel">비밀번호 설정</label>
            <div class="toggle-container">
              <q-toggle
                v-model="secureToggle"
                :label="secureToggle ? '활성화' : '비활성화'"
                :color="toggleSecureColor"
                class="featureToggle"
              />
            </div>
            <input
              v-if="secureToggle"
              type="password"
              id="toneInput"
              v-model="channelSecureStr4"
              placeholder="비밀번호 4자리"
              maxlength="4"
              class="featureInput"
            />
          </div>
        </div>
        <div class="channelDesc">
          <label class="fontSm oneLine" for="channelIntro"
            >채널 한줄 소개</label
          >
          <textarea
            id="channelIntro"
            v-model="channelOneLineDesc"
            placeholder="간단한 채널 소개를 입력해주세요."
          ></textarea>
        </div>
      </div>
    </template>

    <template #actions>
      <DialogRoot v-model:open="popStore.confirmAskPopYn">
        <confirmAskPop />
      </DialogRoot>
      <button @click="saveNewChannel" class="ok">저장</button>
      <button @click="pClosePop()">취소</button>
    </template>
  </CommonPopLayer>
</template>

<style lang="scss" scoped>
.channelCreatePopLayout {
  height: auto;
  width: 80vw !important;
  /* max-width: 550px; */
  max-width: 380px !important;
  max-height: 80vh !important;
}

.channelCreateWrap {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & .channelImg {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid gray;
    margin: 0 auto 30px;
  }

  & .ChannelInfos {
    width: 100%;
    justify-content: start;
    flex-direction: column;
    gap: 10px;

    & > div {
      width: 100%;

      & input {
        width: calc(100% - 100px);
        border: 1px solid gray;
        height: 26px;
      }

      & label {
        width: 100px;
        display: inline-block;
      }

      & select {
        height: 26px;
      }
    }
  }

  & .channelDesc {
    & label {
      width: 100%;
      display: block;
      padding-bottom: 10px;
    }

    & textarea {
      width: 100%;
      height: auto;
      min-height: 80px;
    }
  }
}

.secureWrap {
  display: flex;
  align-items: center;
}

.toggle-container {
  display: flex;
  align-items: center;
}
.featureInput {
  width: 30% !important;
  margin-left: 10px;
}
.toggle {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 34px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #03ceae; /* 활성화 색상 */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

:deep(.popLayer),
:deep(.CommonPopLayer),
:deep(.DialogContent) {
  z-index: 1200 !important;
}

:deep(.DialogOverlay) {
  z-index: 1100 !important;
}
</style>
